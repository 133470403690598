import { useSelector } from "react-redux";
import { MenuComponent } from "../../components";
import { isMobileDevice } from "../../constants";
import { RootState } from "../../reducers";

/* export const renderMenu = (mainMenu: any) => {
  return Object.keys(mainMenu!)?.map(() => {
    <div className="item-class">{"item"}</div>;
  });
}; */

/* export const MenuItems = (mainMenu: any) => {
  console.log(mainMenu);
  return {
    Object.keys()
  };
}; */

export const MainMenu = () => {
  // const [, setWidth] = useState<number>(window.innerWidth);
  // const contentWelcome = useSelector((state: RootState) => state.common.contentWelcome);
  const contentFooter = useSelector((state: RootState) => state.common.contentFooter);
  const { headerInfo } = useSelector((state: RootState) => state.event);
  const { isOpen } = useSelector((state: RootState) => state.auth);
  // const welcomeContent = contentWelcome?.result;
  const copyrightMessage = contentFooter?.copyright;

  /* const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }; */

  /* useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []); */

  return (
    <div className={isMobileDevice && isOpen ? "col-xs-12 col-md-12 col-lg-12 float-left p-0 scroll-container" : "closed"}>
      <div className="menu" role="navigation">
        {headerInfo && !(Object.keys(headerInfo).length === 0 && headerInfo.constructor === Object) && (
          <div className="header-info-holder">
            <p>
              {headerInfo?.firsName && <span className="gray-text">{headerInfo?.firsName} </span>}
              {headerInfo?.lastName && <span className="gray-text">{headerInfo?.lastName} </span>}
              {headerInfo?.divisionName && <span>{headerInfo?.divisionName}</span>}
            </p>
            {headerInfo.headerEffectiveDate && (
              <div>
                {headerInfo.headerEffectiveDate && <p>{headerInfo.headerEffectiveDate}</p>}
                {headerInfo.effectiveDateText && <strong className="d-block">{headerInfo.effectiveDateText}</strong>}
              </div>
            )}
          </div>
        )}
        <MenuComponent />

        {copyrightMessage && (
          <div className="d-lg-none d-md-block">
            <span className="ml-copyright" dangerouslySetInnerHTML={{ __html: copyrightMessage }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default MainMenu;
