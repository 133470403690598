import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { EnumCoverageList, pastCoverageList, showError } from "../../actions";
import { ObjectUtils } from "../../utils";
import { AlertComponent, CoverageListComponent } from "../../components";
//import { useSelector RootState } from "../../reducers";
// import { MainMenu } from "../../containers";

export const ReviewCoverage = () => {
  const dispatch = useDispatch();
  const [coverageList, setcoverageList] = useState<EnumCoverageList>();
  //const coverageList = useSelector((state: RootState) => state.coverage.currentCoverage);
  /**
   * This function will execute once this component
   * has been mounted.
   */
  useEffect(() => {
    //This gets app config data and save into redux store

    !coverageList && dispatch(
      pastCoverageList(
        (response) => {
          response ? setcoverageList(response) : showError(response.errorMessage);
        },
        (errorMessage) => {
          showError(errorMessage);
        }
      )
    );
  }, [dispatch, coverageList]);

  const coverageContent = coverageList?.contentResource.contents || {};
  const lblPageTitle = ObjectUtils.extractLabelFromObject(coverageContent, "lblPageTitle");
  const btnDownload = ObjectUtils.extractLabelFromObject(coverageContent, "btnDownload");
  return (
    <div className={`pastcoveragelist clearfix pt-md-4 pb-md-4`}>
      {coverageList && (
        <AlertComponent
          propmessageType="info"
          propmessage={`${coverageList?.firsName} ${coverageList?.lastName}`}
          propmessage1={coverageList?.currentDate}
          propmessageclass="m-0 fw-bold"
        />
      )}
      {coverageList && (
        <CoverageListComponent
          reviewPastCoverage={coverageList?.previousCoverage}
          btnDownload={btnDownload.display}
          pageTitle={lblPageTitle.display}
        />
      )}
    </div>
  );
};

export default ReviewCoverage;
