/* eslint-disable @typescript-eslint/no-unused-vars */
import { useDispatch, useSelector } from "react-redux";
import { AlertComponent, ButtonComponent, CardComponent, ConfirmedItemComponent, DescriptionComponent, FormComponent, ModalComponent } from "../../components";
import { RootState } from "../../reducers";
import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from "react";

import { ObjectUtils, USER_TOKEN } from "../../utils";
import { TrashIcon } from "../../assets/icons";
import { deleteDirectBilling, downloadFinishedEnrollmentFile, getDirectBilling } from "../../actions";
import { useNavigate } from "react-router-dom";

export const ViewDirectBilling = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showModal, setModal] = useState<boolean>(false);
  const lang = USER_TOKEN.get()?.lang;
  const { billingInfo } = useSelector((state: RootState) => state?.billing);
  const content = billingInfo?.contentResource?.contents || [];
  const { showAddButton, showDeleteButton, bankAccountDetails, institutionsList, downloadPADAgreementForm } = billingInfo?.result ?? {};

  const lblTitle = ObjectUtils.extractLabelFromObject(content, "lblTitle");
  const lblNoAccount = ObjectUtils.extractLabelFromObject(content, "lblNoAccount");
  const lblInstitutionNumber = ObjectUtils.extractLabelFromObject(content, "lblInstitutionNumber");
  const lblInstitutionName = ObjectUtils.extractLabelFromObject(content, "lblInstitutionName");
  const lblTransitNumber = ObjectUtils.extractLabelFromObject(content, "lblTransitNumber");
  const lblAccountNumber = ObjectUtils.extractLabelFromObject(content, "lblAccountNumber");
  const btnAddBankAccount = ObjectUtils.extractLabelFromObject(content, "btnAddBankAccount");
  const lblTitleDelete = ObjectUtils.extractLabelFromObject(content, "lblTitleDelete");
  const lblDeleteDetail = ObjectUtils.extractLabelFromObject(content, "lblDeleteDetail");
  const lblTitleSuccess = ObjectUtils.extractLabelFromObject(content, "lblTitleSuccess");

  const btnDelete = ObjectUtils.extractLabelFromObject(content, "btnDelete");
  const btnCancel = ObjectUtils.extractLabelFromObject(content, "btnCancel");

  document.title = lblTitle?.display;

  const loadBilling = useCallback(async () => {
    dispatch(await getDirectBilling());
  }, [dispatch]);

  useEffect(() => {
    loadBilling();
  }, [loadBilling]);

  const showDeleteConfirmModal = async () => {
    window.history.replaceState({}, "");
    setModal(true);
  };

  const head = {
    gridtitle: lblTitle?.display,
    showdeleteicon: showDeleteButton,
    deletebtndata: {
      className: "edit-icon float-right",
      ariaLabel: (btnDelete?.textToRead || btnDelete?.display || "") + " " + lblTitle?.display,
      iconbtn: <TrashIcon />,
      onSubmit: showDeleteConfirmModal
    }
  };

  const body = {
    gridRowData: [
      [
        bankAccountDetails?.institutionNumber && {
          type: "text",
          name: "institutionNumber",
          display: lblInstitutionNumber?.display,
          texttoread: lblInstitutionNumber?.textToRead,
          mode: "view",
          value: bankAccountDetails?.institutionNumber,
          fgclass: "row mb-1",
          colclassname: "col-12",
          lblclassname: "col-md-6 col-form-label"
        },
        bankAccountDetails?.institutionName && {
          type: "text",
          name: "institutionName",
          display: lblInstitutionName?.display,
          texttoread: lblInstitutionName?.textToRead,
          mode: "view",
          value: bankAccountDetails?.institutionName,
          fgclass: "row mb-1",
          colclassname: "col-12",
          lblclassname: "col-md-6 col-form-label"
        },
        bankAccountDetails?.transitNumber && {
          type: "text",
          name: "transitNumber",
          display: lblTransitNumber?.display,
          texttoread: lblTransitNumber?.textToRead,
          mode: "view",
          value: bankAccountDetails?.transitNumber,
          fgclass: "row mb-1",
          colclassname: "col-12",
          lblclassname: "col-md-6 col-form-label"
        },
        bankAccountDetails?.accountNumber && {
          type: "text",
          name: "accountNumber",
          display: lblAccountNumber?.display,
          texttoread: lblAccountNumber?.textToRead,
          mode: "view",
          value: bankAccountDetails?.accountNumber,
          fgclass: "row mb-1",
          colclassname: "col-12",
          lblclassname: "col-md-6 col-form-label"
        },
        !bankAccountDetails?.accountNumber && {
          type: "text",
          display: lblNoAccount.display,
          texttoread: lblNoAccount.textToRead,
          fgclass: "mt-2 mb-3 row",
          colclassname: "col-sm-12 col-md-12"
        }
      ]
    ]
  };

  const beneficiaryNavigationClicked = () => {
    downloadFinishedEnrollmentFile(downloadPADAgreementForm?.btnNavigateTo, downloadPADAgreementForm?.fileName);
  };

  const onButtonClick = async (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    navigate("/directbilling/create");
  };

  const padAgreementDownloadData = [
    {
      buttontitle: downloadPADAgreementForm?.name,
      isexists: true,
      navigateclicked: beneficiaryNavigationClicked
    }
  ];

  const handleClose = () => {
    setModal(false);
  };

  const deleteBillingModal = {
    showModal: showModal,
    clickHandler: handleClose,
    dialogClass: "infopopup",
    head: {
      modalTitle: lblTitleDelete.display
    },
    body: {
      confirmBoxData: {
        messagetext: lblDeleteDetail.display
      }
    }
  };

  const handleSubmit = async (event: FormEvent<EventTarget>): Promise<void> => {
    event.preventDefault();
    dispatch(await deleteDirectBilling());
    handleClose();
    loadBilling();
  };

  const handleAlertClose = () => {
    window.history.replaceState({}, "");
  };

  // Fix: on lang change, reset history state without component rerendering.
  useEffect(() => {
    return () => {
      window.history.replaceState({}, "");
    };
  }, [lang]);

  useEffect(() => {
    if (localStorage.getItem("hideButtons") === "true") {
      localStorage.removeItem("hideButtons");
    }
    USER_TOKEN.removeDirectbilling();
  }, []);

  const formInput = {
    classname: "loginform",
    handlesubmit: handleSubmit
  };

  const buttonInput = [
    {
      variant: "outline-secondary btn-cancel btn-sm",
      name: "cancelbtn",
      display: btnCancel?.display,
      onClick: handleClose
    },
    {
      type: "submit",
      variant: "secondary btn-next btn-sm float-end",
      name: "btnDelete",
      display: btnDelete?.display
    }
  ];

  return (
    <>
      {history.state?.usr?.reload && <AlertComponent propmessageType={`success`} propmessage={lblTitleSuccess.display} closeIcon={true} onDismiss={handleAlertClose} />}
      {billingInfo ? (
        <div className="row">
          <div className="container">
            <div className="row">
              <div className="w-100">
                <CardComponent
                  gridObject={{
                    head,
                    body
                  }}
                >
                  {downloadPADAgreementForm && <ConfirmedItemComponent finishEnrollmentData={padAgreementDownloadData} />}

                  {showAddButton && (
                    <ButtonComponent
                      btndata={[
                        {
                          variant: "secondary mb-4 mx-2",
                          display: btnAddBankAccount?.display,
                          onSubmit: onButtonClick
                        }
                      ]}
                    />
                  )}
                </CardComponent>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {showModal && (
        <ModalComponent modalObject={deleteBillingModal}>
          <FormComponent
            formdata={formInput}
            btndata={buttonInput}
            gridRowData={body?.gridRowData}
            btnviewmode={1}
            btnviewclass="d-flex justify-content-between border-top pt-3 pb-2 mt-5"
          />
        </ModalComponent>
      )}
    </>
  );
};

export default ViewDirectBilling;
