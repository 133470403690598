import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EnumScreenObject, cancelEnrollment, getLifeChangeContent, putWelcomeContent, showError } from "../../actions";
import { AlertComponent, CalenderComponent, ChoiceListComponent, ModalComponent, NextPrevComponent } from "../../components";
import { Welcome, navigateChangedState } from "../../constants";
import { RootState } from "../../reducers";
import { ObjectUtils } from "../../utils";

type initialState = {
  lifeEventEffectiveDateSelectedDate: Date;
  lifeEventEffectiveDateMinDate: Date;
  lifeEventEffectiveDateMaxDate: Date;
  lifeEventOptionSelected: number;
  formErrors: { options: string; date: string };
  optionsValid: boolean;
};

export const LifeChangeEvent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const location = useLocation();

  // const [lifeChangeContent, setLifeChangeContent] = useState<EnumLifeChangeContent>();
  const lifeChangeContent = useSelector((state: RootState) => state.event.lifeEventChangeContent);
  const [dateValid, setDateValid] = useState<boolean>(false);
  const [formValid, setFormValid] = useState<boolean>(false);
  const [isDisclaimerModalVisible, setIsDisclaimerModalVisible] = useState<boolean>(false);
  const [lifeEventOptionSelected, setLifeEventOptionSelected] = useState<number>(0);

  const eventContent: any = lifeChangeContent?.result;
  const contents = lifeChangeContent?.contentResource?.contents;
  const [comingState, setComingState] = useState<initialState>({
    lifeEventEffectiveDateSelectedDate: new Date(eventContent?.lifeEventEffectiveDateSelectedDate),
    lifeEventEffectiveDateMinDate: new Date(eventContent?.lifeEventEffectiveDateMinDate),
    lifeEventEffectiveDateMaxDate: new Date(eventContent?.lifeEventEffectiveDateMaxDate),
    lifeEventOptionSelected: 0,
    formErrors: { options: "", date: "" },
    optionsValid: false
  });

  const validateForm = useCallback(() => {
    setFormValid(comingState?.optionsValid && dateValid);
  }, [comingState?.optionsValid, dateValid]);

  const initializeTheStartDate = useCallback(() => {
    const selected_date = new Date(comingState?.lifeEventEffectiveDateSelectedDate)?.toDateString();
    const min_date = new Date(comingState?.lifeEventEffectiveDateMinDate)?.toDateString();
    const max_date = new Date(comingState?.lifeEventEffectiveDateMaxDate)?.toDateString();

    if (selected_date === max_date || selected_date === min_date) {
      setDateValid(true);
    } else {
      // setDateValid(false);
    }

    validateForm();
  }, [comingState?.lifeEventEffectiveDateMaxDate, comingState?.lifeEventEffectiveDateMinDate, comingState?.lifeEventEffectiveDateSelectedDate, validateForm]);

  const validateField = (fieldName: string, value: any) => {
    switch (fieldName) {
      case "lifeEventOptionSelected":
        setComingState({ ...comingState, optionsValid: true });
        setDateValid(!isNaN(comingState?.lifeEventEffectiveDateSelectedDate?.getTime()));
        break;
      case "lifeEventEffectiveDateSelectedDate":
        const isValid = value >= comingState?.lifeEventEffectiveDateMinDate && value <= comingState?.lifeEventEffectiveDateMaxDate;
        setDateValid(isValid);
        comingState.formErrors.date = isValid ? "" : ObjectUtils.extractLabelFromObject(contents, "lblLifeEventEffectiveDateLabel").display;
        setComingState({ ...comingState });
        break;
      default:
        break;
    }

    validateForm();
  };

  const handleDateChange = (eventDate: any) => {
    /* const now = comingState?.lifeEventEffectiveDateSelectedDate;
    
    
    const newEventDate = new Date(
      eventDate["year"] +
        "/" +
        eventDate["month"] +
        "/" +
        eventDate["day"] +
        " " +
        now.getHours() +
        ":" +
        now.getMinutes() +
        ":" +
        now.getSeconds()
    ); */

    comingState.lifeEventEffectiveDateSelectedDate = eventDate;

    setComingState({ ...comingState });
    validateField("lifeEventEffectiveDateSelectedDate", eventDate);
  };

  const cancelClicked = () => {
    const screen: EnumScreenObject = Welcome;
    dispatch(
      cancelEnrollment(
        screen,
        () => {
          navigate(`/${screen.link}`, navigateChangedState);
        },
        (errorMessage) => {
          showError(errorMessage);
        }
      )
    );
  };

  useEffect(() => {
    dispatch(
      getLifeChangeContent(
        (response) => {
          const result = response?.result;
          const selectedDate = !result?.hideLifeEventEffectiveDate ? result?.lifeEventEffectiveDateSelectedDate : "";
          setComingState((prevState) => ({
            ...prevState,
            lifeEventEffectiveDateSelectedDate: new Date(selectedDate),
            lifeEventEffectiveDateMinDate: new Date(result?.lifeEventEffectiveDateMinDate),
            lifeEventEffectiveDateMaxDate: new Date(result?.lifeEventEffectiveDateMaxDate)
          }));
        },
        (errorMessage) => {
          showError(errorMessage);
        }
      )
    );
  }, [dispatch]);

  useEffect(() => {
    initializeTheStartDate();
  }, [initializeTheStartDate]);

  const lifeEventIntroTextVersion = eventContent?.lifeEventIntroTextVersion;
  const lifeEventDateError = ObjectUtils.extractLabelFromObject(contents, "lifeEventDateError");
  const lblDatePlaceHolder = ObjectUtils.extractLabelFromObject(contents, "lblDatePlaceHolder");
  const lblLifeEventEffectiveDateLabel = ObjectUtils.extractLabelFromObject(contents, "lblLifeEventEffectiveDateLabel");
  const lblLifeEventChangeDateToProceed = ObjectUtils.extractLabelFromObject(contents, "lblLifeEventChangeDateToProceed");
  const lnkStartLifeEvent = ObjectUtils.extractLabelFromObject(contents, "lnkStartLifeEvent");

  const lblDisclaimerTitle = ObjectUtils.extractLabelFromObject(contents, "lblDisclaimerTitle");
  const lifeEventDisclaimer = ObjectUtils.extractLabelFromObject(contents, "LifeEventDisclaimer");

  const iAgree = ObjectUtils.extractLabelFromObject(contents, "lblIAgree");
  const idontAgree = ObjectUtils.extractLabelFromObject(contents, "lblIDisagree");
  const btnCancel = ObjectUtils.extractLabelFromObject(contents, "btnCancel");
  const btnNext = ObjectUtils.extractLabelFromObject(contents, "btnNext");

  const hideLifeEventEffectiveDate = eventContent?.hideLifeEventEffectiveDate;
  const showLifeEventIntroTextBottom = eventContent?.showLifeEventIntroTextBottom;

  // const localestring = USER_TOKEN?.get()?.lang?.substring(0, 2);

  const lifeEventList = eventContent?.lifeEventList
    ?.sort((a: { sequence: number }, b: { sequence: number }) => a.sequence - b.sequence)
    ?.filter((item: { isUsed: boolean }) => item.isUsed)
    ?.map(({ text, value, type }) => {
      return {
        name: type,
        text,
        value
      };
    });

  //

  const handleSelection = (callback: any) => {
    const key: any = Object.keys(callback)[0];
    const value: any = Object.values(callback)[0];
    setLifeEventOptionSelected(value);
    validateField(key, value);
  };

  const options = {
    component: "lifeEventChange",
    name: "lifeEventOptionSelected",
    selected: lifeEventOptionSelected,
    handlechange: handleSelection,
    fgclass: "mt-2 mb-4",
    optionsinput: lifeEventList
  };
  //

  const agreeDisclaimerPresses = async () => {
    validateField("lifeEventOptionSelected", lifeEventOptionSelected);
    validateField("lifeEventEffectiveDateSelectedDate", comingState?.lifeEventEffectiveDateSelectedDate);
    if (formValid) {
      const eventDate = comingState?.lifeEventEffectiveDateSelectedDate;
      console.log("eventDate: ", eventDate);

      const caldate = {
        month: eventDate.getMonth() + 1,
        day: eventDate.getDate(),
        year: eventDate.getFullYear()
      };

      const obj = {
        selectedLink: lnkStartLifeEvent?.keyName,
        lifeEventDate: caldate,
        lifeEventType: lifeEventOptionSelected
      };

      dispatch(await putWelcomeContent(navigate, null, null, null, obj));
      localStorage.setItem("lifeEvent", "true");
    }
  };

  const modalObject = {
    showModal: isDisclaimerModalVisible,
    head: {
      modalTitle: lnkStartLifeEvent.display,
      texttoRead: lnkStartLifeEvent.textToRead
    },
    body: {
      confirmBoxData: {
        messagetitle: lblDisclaimerTitle.display,
        messagetext: lifeEventDisclaimer.display,
        wrapper: "ml-lifechangeeventcontent",
        classname: " "
      },
      showNextPrev: {
        agreeDisclaimerPresses: agreeDisclaimerPresses,
        cancelClicked: cancelClicked,
        iAgree,
        idontAgree,
        showButtons: true
      }
    }
  };

  return (
    <div className="row justify-content-around">
      {/* <FormErrors formErrors={this.state.formErrors} /> */}
      <div className="col-xs-12 col-md-10 col-lg-8">
        {/* <div className="mb-4 mb-lg-4" dangerouslySetInnerHTML={{ __html: lifeEventIntroTextVersion }} /> */}
        {!showLifeEventIntroTextBottom && <div className="mb-4 mb-lg-5" dangerouslySetInnerHTML={{ __html: lifeEventIntroTextVersion }} />}
        {eventContent?.showLifeEventContinueButton && (
          <>
            <div className="mb-2">
              <div className="ml-inputfields ml-dateofbirth form-group w-100">
                <div className={`position-relative life-event`}>
                  <CalenderComponent
                    className="ml-datecomponent"
                    name="lifeEventEffectiveDateSelectedDate"
                    type="text"
                    placeholder={lblDatePlaceHolder.display}
                    display={lblLifeEventEffectiveDateLabel.display}
                    aria-label={lblLifeEventEffectiveDateLabel.textToRead}
                    mode="edit"
                    min_date={comingState?.lifeEventEffectiveDateMinDate}
                    max_date={comingState?.lifeEventEffectiveDateMaxDate}
                    date={comingState?.lifeEventEffectiveDateSelectedDate}
                    onChange={handleDateChange}
                    // localestring={localestring}
                    isreadonly="true"
                    islifeevent="true"
                    datevalid={dateValid?.toString()}
                    hidelifeeventeffectivedate={hideLifeEventEffectiveDate?.toString()}
                    // errorMessage={!dateValid ? lifeEventDateError.display : ""}
                  />
                </div>
                {!dateValid ? (
                  <div className="lifeevent-error invalid-feedback d-block w-100" role="alert">
                    {lifeEventDateError.display}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {hideLifeEventEffectiveDate && lblLifeEventChangeDateToProceed && <AlertComponent marginleft={false} propmessageType="primary" propmessage={lblLifeEventChangeDateToProceed.display} />}
            <div className="ml-lifeevents pt-3 pb-md-3 clearfix">
              <ChoiceListComponent choicelistdata={options} />
              {/* <NewMultiRadioComponent
                component="lifeEventChange"
                name="lifeEventOptionSelected"
                selected={this.state.lifeEventOptionSelected ? this.state.lifeEventOptionSelected.toString() : 0}
                type="radio"
                options={lifeEventList}
                keyy={`${this.props.keyy}-lifeEventList`}
                onChange={this.setValue}
              /> */}
            </div>
          </>
        )}
        {showLifeEventIntroTextBottom && <div className="mb-4" dangerouslySetInnerHTML={{ __html: lifeEventIntroTextVersion }} />}
        <div className="ml-btnslifechangeevent clearfix">
          <NextPrevComponent
            lastPage={!eventContent?.showLifeEventContinueButton}
            firstPage={true}
            nextClick={() => setIsDisclaimerModalVisible(true)}
            cancelClicked={cancelClicked}
            previousClick={cancelClicked}
            nextDisabled={!formValid}
            lblNext={btnNext}
            lblCancel={btnCancel}
            lblPrevious={btnCancel}
            showButtons={true}
          />
        </div>
      </div>
      <ModalComponent modalObject={modalObject} />
    </div>
  );
};
export default LifeChangeEvent;
