/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
// import {useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getClientStylesContent, getHeaderImages, toggleSideBar } from "../../actions";
import { RootState } from "../../reducers";
import { EventUtils, ObjectUtils } from "../../utils";
import { USER_TOKEN } from "../../utils/Storage";
import { MainMenu } from "../Menu";

export const Header = (_props: any) => {
  const { isSignedIn, needTwoFAValidation } = USER_TOKEN.get();
  const dispatch = useDispatch();

  const location = useLocation();
  const { isOpen } = useSelector((state: RootState) => state.auth);
  const { client, contentHeader } = useSelector((state: RootState) => state.common);
  const { headerNavURL } = useSelector((state: RootState) => state.event);

  const locale = USER_TOKEN.get()?.lang;
  const [clientState, setClientState] = useState<string>(client);

  const clientStyles = JSON.parse(localStorage.getItem("clientStyles") || "{}");
  // console.log("isMenuOpen: ", props.isMenuOpen);

  const loadHC = useCallback(async () => {
    dispatch(getHeaderImages());
  }, [dispatch]);

  useEffect(() => {
    if (!contentHeader) {
      loadHC();
    }
  }, [contentHeader, loadHC]);

  useEffect(() => {
    if (clientState !== client) {
      getClientStylesContent(true);
      setClientState(client);
    }
    if (Object.keys(clientStyles)?.length > 0) {
      ObjectUtils.modifyJsonObject(clientStyles);
    }
  }, [client, clientState, clientStyles]);

  const handleToggleSidebar = () => {
    toggleSideBar(isOpen ? false : true);
  };

  const changeLocaleLanguage = (locale: string) => {
   USER_TOKEN.get().languageSwitchRequired && USER_TOKEN.removeQCUser();
    EventUtils.changeLocale(locale, location);
  };

  const contents = contentHeader?.contentResource?.contents;
  const logos = contentHeader?.result;

  const style = JSON.parse(localStorage.getItem("style") || "{}");
  const labelEN = ObjectUtils.extractLabelFromObject(contents, "labelEN");
  const labelFR = ObjectUtils.extractLabelFromObject(contents, "labelFR");
  const toolTipEn = ObjectUtils.extractLabelFromObject(contents, "toolTipEn");
  const toolTipFr = ObjectUtils.extractLabelFromObject(contents, "toolTipFr");
  const altText = ObjectUtils.extractLabelFromObject(contents, "logoAltText");
  const clientLogoAltText = ObjectUtils.extractLabelFromObject(contents, "clientLogoAltText");
  const skipContent = ObjectUtils.extractLabelFromObject(contents, "skipContent");
  const AllowFrench = !style ? "True" : style["AllowFrench"] !== null ? style["AllowFrench"] : "True";

  return (
    <div className="nav-holder" id="mainHeader">
      <Navbar bg="" aria-label="nav-header" className="container custom-navbar-styling hide-desktop-menu show-side-nav" expanded={isOpen} expand="lg">
        <span className={`main-content-skip-nav-link btn-link ${isOpen ? "hide" : "show"}`} tabIndex={0} role="link" onKeyDown={EventUtils.skipMainContentKeyDown} onClick={EventUtils.skipMainContent}>
          {skipContent.display}
        </span>
        {isSignedIn && !needTwoFAValidation && !/\/changepassword$/g.test(window.location.pathname) && <Navbar.Toggle aria-controls="basic-navbar-nav" tabIndex={0} onClick={handleToggleSidebar} />}
        <div className="logos-holder">
          <span className="img--holder">
            <Navbar.Brand className="text-center" tabIndex={0} role="link">
              <img src={logos?.headerLogo} className="d-inline-block align-top" alt={altText.textToRead} />
            </Navbar.Brand>
          </span>
          <span className="img--holder">
            <Navbar.Brand tabIndex={0} className="text-center" href={headerNavURL} role="link">
              <img src={logos?.clientLogo} className="d-inline-block align-top" alt={clientLogoAltText.textToRead} />
            </Navbar.Brand>
          </span>
        </div>

        <Navbar.Collapse id="basic-navbar-nav" className="nav-links-holder justify-content-end">
          {isSignedIn && !/\/changepassword$/g.test(window.location.pathname) && (
            <Nav className="side-nav-holder-mobile">
              {isOpen && <MainMenu />}
              {/* <SideMenu content={this.props.sideBarMenu} isOpen={props.isOpen} isMobile={true} /> */}
            </Nav>
          )}
          {AllowFrench === "True" && (
            <Nav className="navbar-text">
              <ul className="language-tab-holder float-right">
                <li className="divider">
                  <Button
                    tabIndex={0}
                    aria-label={toolTipEn.textToRead}
                    data-toggle="tooltip"
                    className={/en/.test(locale) ? "lang-btn active bold-text" : "lang-btn"}
                    variant="secondary"
                    disabled={/en/.test(locale)}
                    onClick={() => changeLocaleLanguage("en")}
                  >
                    {labelEN.display}
                  </Button>
                </li>

                <li>
                  <Button
                    data-toggle="tooltip"
                    tabIndex={0}
                    aria-label={toolTipFr.textToRead}
                    className={/fr/.test(locale) ? "lang-btn active bold-text" : "lang-btn"}
                    variant="secondary"
                    disabled={/fr/.test(locale)}
                    onClick={() => changeLocaleLanguage("fr")}
                  >
                    {labelFR.display}
                  </Button>
                </li>
              </ul>
              {/* {this.props.isSignedIn && <Nav.Link onClick={()=>{this.props.logoff()}} title={logoffText.textToRead} aria-label={logoffText.textToRead}>{logoffText.display}</Nav.Link>} */}
            </Nav>
          )}
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
