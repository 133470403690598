import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {setPageSetting, EnumCurrentCoverage, pdfStatement, reviewCurrentCoverage, reviewPastCoverage } from "../../actions";
import { CoverageComponent } from "../../components";
import { RootState } from "../../reducers";
import { ObjectUtils } from "../../utils";
import _ from "underscore";
import { navigateChangedState } from "../../constants";

export const Coverage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const contentMainMenu = useSelector((state: RootState) => state.common.screens || [{}]);
  const { currentCoverage, pastCoverage } = useSelector((state: RootState) => state.coverage);
  const contentFooter = useSelector((state: RootState) => state.common.contentFooter);
  const hasDateStringParam = location?.search.includes("dateString");
  const [coverage, setCoverage] = useState<EnumCurrentCoverage | null>(null);
  const { pageSetting } = useSelector((state: RootState) => state.event);
  const footerContent = contentFooter?.contentResource?.contents;
  const backButtonText = ObjectUtils.extractLabelFromObject(footerContent || {}, "btnBack");

  const requestInput = useCallback((): string | null => {
    if (hasDateStringParam) {
      return new URLSearchParams(location?.search).get("dateString");
    } else {
      const query: string | null = location?.search.includes("period=Future") ? location.search.replace("?", "") : null;
      return query;
    }
  }, [hasDateStringParam, location?.search]);

  const handleClick = useCallback(() => {
    if (coverage) {
      const period = requestInput();
      const requestParam = period?.includes("period") || !period ? "Current" : period;
      pdfStatement(coverage.buttonSavePDF?.btnNavigateTo, requestParam);
    }
  }, [coverage, requestInput]);

  const handlePastCoverage = useCallback(() => {
    const dateString = requestInput() ?? ""; // Providing a default empty string value if dateString is null
    dispatch(reviewPastCoverage(dateString));
  }, [dispatch, requestInput]);

  const handleCurrentCoverage = useCallback(() => {
    const dateString = requestInput() ?? ""; // Providing a default empty string value if dateString is null
    dispatch(reviewCurrentCoverage(dateString));
  }, [dispatch, requestInput]);

  useEffect(() => {
    if (hasDateStringParam) {
      !pastCoverage && handlePastCoverage();
    } else {
      !currentCoverage && handleCurrentCoverage();
    }
  }, [currentCoverage, handleCurrentCoverage, handlePastCoverage, hasDateStringParam, pastCoverage]);

  useEffect(() => {
    pastCoverage && setCoverage(pastCoverage);

    currentCoverage && setCoverage(currentCoverage);
  }, [currentCoverage, pastCoverage]);

  const previousPage = () => {
    if(localStorage.getItem('pageName')){
		const menuItem = _.find(contentMainMenu, item => item.name === localStorage.getItem('pageName'))
    setPageSetting(localStorage.getItem('pageName'));
     localStorage.removeItem('pageName')
      navigate(`/${menuItem ? menuItem.link : ""}`);
		} else
     pageSetting.link?navigate(`/${pageSetting.link}`):navigate("/", navigateChangedState)
   
  };

  const buttonInput = [
    coverage?.buttonSavePDF && {
      type: "submit",
      className: "secondary btn-submit float-end",
      display: coverage?.buttonSavePDF?.display,
      onSubmit: handleClick
    }
  ];

  const buttonBack = [
    backButtonText?.display && {
      display: backButtonText.display,
      variant: "link",
      rightpos: true,
      displayclass: "m-0 mx-3",
      className: "float-left",
      icon: faChevronLeft,
      onSubmit: previousPage
    }
  ];

  return <> {coverage ? <CoverageComponent buttonInput={buttonInput} buttonBack={buttonBack} coverageData={coverage} /> : null}</>;
};

export default Coverage;
