import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import { downloadFinishedEnrollmentFile, finishEnrollment } from "../../actions";
import { AlertComponent, ButtonComponent } from "../../components";
import { ConfirmedItemComponent, EnrollmentBenefitComponent } from "../../components/Coverages"; //
import { DescriptionComponent } from "../../components/Form";
import { navigateChangedState } from "../../constants";
import { RootState } from "../../reducers";
import { ObjectUtils } from "../../utils";

export const EnrollmentConfirmation = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  // const [finishEnrollmentData] = useState<any>();
  const { contentFooter } = useSelector((state: RootState) => state.common);
  const finishEnrollmentData = useSelector((state: RootState) => state.coverage.finishEnrollment);
  const simpleButtonColor = "#f4f9f2";
  const match = useMatch("/FinishedEnrollment/Forms");
  const formStatus = match ? 1 : 0;
  const queryParam = location?.search?.indexOf("period") > -1 ? location?.search : "";
  localStorage.removeItem("hideButtons");
  /**
   * This function will execute once this component
   * has been mounted.
   */

  useEffect(() => {
    async function fetchFinishEnrollment() {
      dispatch(await finishEnrollment(formStatus, queryParam));
    }

    fetchFinishEnrollment();
  }, [dispatch, formStatus, queryParam]);

  const downloadFinishedEnrollmentFileClicked = (formNavigationUrl: string, fileName: string) => {
    downloadFinishedEnrollmentFile(formNavigationUrl, fileName);
  };
  const uploadButtonNavigateClicked = () => {
    navigate(`/${finishEnrollmentData?.fileUploadButtonlnk}`);
  };
  const confirmationTitle = finishEnrollmentData?.finishConfirmPageTitle;
  document.title = confirmationTitle;
  const lblNewTab = ObjectUtils.extractLabelFromObject(contentFooter || {}, "lblNewTab");
  /* E-Signature Start */
  const isEsignable = finishEnrollmentData?.isEsignable || false;
  const beneficiaryESign = finishEnrollmentData?.beneficiaryESign || null;
  /* E-Signature End */

  const eobEnabledList: any = finishEnrollmentData?.eoiFormVisible && finishEnrollmentData?.eoiForms && finishEnrollmentData?.eoiForms?.list ? finishEnrollmentData.eoiForms.list : [];

  const lblBottomMessage = finishEnrollmentData?.lblBtmMSG ? finishEnrollmentData.lblBtmMSG : "";
  const btnNavigateTo = finishEnrollmentData?.confirmationStatment?.btnNavigateTo;
  // const param = btnNavigateTo?.indexOf("?") > -1 ? btnNavigateTo.split("?")[1] : "";
  const [, param] = btnNavigateTo?.indexOf("?") > -1 ? btnNavigateTo.split("?") : "";

  const confimationStatementMessage = finishEnrollmentData?.confirmationStatmentVisible ? finishEnrollmentData?.confirmationStatment?.message : "";
  const confimationStatementMessageType = finishEnrollmentData?.confirmationStatmentVisible ? finishEnrollmentData?.confirmationStatment?.messageType : "";

  const finishEnrollmentObject = [
    /* Confirmation Statment Start */
    {
      isexists: finishEnrollmentData?.confirmationStatmentVisible,
      lblnewtabtext: lblNewTab?.textToRead,
      messagetype: finishEnrollmentData?.confimationStatementMessageType,
      message: finishEnrollmentData?.confimationStatementMessage,
      title: finishEnrollmentData?.confirmationStatment?.name,
      buttontitle: finishEnrollmentData?.downloadButtonLabel,
      confimationstatementmessage: finishEnrollmentData?.confimationStatementMessage,
      btnnavigateto: finishEnrollmentData?.confirmationStatment?.btnNavigateTo,
      parameters: param,
      confirmationstatment: true,
      backcolor: simpleButtonColor
    },
    /* Confirmation Statment End */
    /* beneficiary Forms Form* Start */
    {
      isexists: finishEnrollmentData?.beneficiaryFormsFormVisible,
      messagetype: finishEnrollmentData?.beneficiaryForms?.messageType,
      message: finishEnrollmentData?.beneficiaryForms?.message,
      title: finishEnrollmentData?.beneficiaryForms?.name,
      buttontitle: finishEnrollmentData?.downloadButtonLabel,
      btnnavigateto: finishEnrollmentData?.beneficiaryForms?.btnNavigateTo,
      navigateclicked: () => downloadFinishedEnrollmentFileClicked(finishEnrollmentData?.beneficiaryForms?.btnNavigateTo, finishEnrollmentData.beneficiaryForms.fileName),
      backcolor: simpleButtonColor
    },
    /* Beneficiary Forms Form End */
    /* E-Signature Start */
    {
      isexists: isEsignable && beneficiaryESign,
      messagetype: beneficiaryESign?.messageType,
      message: beneficiaryESign?.message,
      title: beneficiaryESign?.name,
      buttontitle: finishEnrollmentData?.downloadButtonLabel,
      btnnavigateto: beneficiaryESign?.btnNavigateTo,
      navigateclicked: () => downloadFinishedEnrollmentFileClicked(beneficiaryESign?.btnNavigateTo, beneficiaryESign?.fileName),
      backcolor: simpleButtonColor
    },
    /* E-Signature End */
    /* RRSP Form  Start */
    {
      isexists: finishEnrollmentData?.rrspFormVisible, // /* rrspFormVisible  */
      messagetype: finishEnrollmentData?.rrsp?.messageType,
      message: finishEnrollmentData?.rrsp?.message,
      title: finishEnrollmentData?.rrsp?.name,
      buttontitle: finishEnrollmentData?.downloadButtonLabel,
      btnnavigateto: finishEnrollmentData?.rrsp?.btnNavigateTo,
      navigateclicked: () => downloadFinishedEnrollmentFileClicked(finishEnrollmentData?.rrsp?.btnNavigateTo, finishEnrollmentData?.rrsp?.fileName),
      backcolor: simpleButtonColor
    }
    /* RRSP Form  End */
  ];

  const homeButtonLabel = finishEnrollmentData?.homeButtonLabel ? finishEnrollmentData?.homeButtonLabel : "";
  const buttonInput = [
    {
      variant: "outline-secondary",
      display: homeButtonLabel,
      onSubmit: () => {
        navigate("/", navigateChangedState);
      }
    },
    finishEnrollmentData?.fileUploadButtonlnk &&
      finishEnrollmentData?.showFileUploadButton && {
        variant: "outline-secondary btn-fileupload",
        display: finishEnrollmentData?.fileUploadButtonLabel,
        onSubmit: uploadButtonNavigateClicked
      }
  ];
  const dangerType = "DANGER";

  return (
    <>
      <div className="enrollmentconfirmation clearfix">
        {confirmationTitle && <DescriptionComponent level="1" headingclassname="caption personal-info-caption" heading={confirmationTitle} />}
        <div className="clearfix mb-md-2">
          <AlertComponent
            marginleft={false}
            propmessageType={confimationStatementMessageType === dangerType ? "clearfix alert alert-danger" : "clearfix pb-3 pb-md-4"}
            propmessage={confimationStatementMessage}
          />
          <ConfirmedItemComponent finishEnrollmentData={finishEnrollmentObject} />
        </div>
        {finishEnrollmentData?.eoiFormVisible && (
          <div className="clearfix m-0 p-md-3 card">
            <AlertComponent
              marginleft={false}
              propmessageType={finishEnrollmentData?.eoiForms?.messageTopType === "DANGER" ? "danger clearfix" : " clearfix pb-3 pb-md-4"}
              propmessage={finishEnrollmentData?.eoiForms?.messageTop}
            />
            {eobEnabledList.map((item: any, index: number) => {
              return (
                <div className="clearfix pb-md-3" key={index}>
                  <div className="clearfix">
                    <EnrollmentBenefitComponent
                      benefittitle={item.name}
                      coverlettertitle={finishEnrollmentData?.coverletterLabel}
                      downloadbuttonlabel={finishEnrollmentData?.downloadButtonLabel}
                      questionairetitle={finishEnrollmentData?.questionnaireLabel}
                      coverletterfile={item.coverLetter}
                      btnnavigateto={item?.btnNavigateTo}
                      questionairefile={item.questionnaire}
                      nocarrier={item.noCarrier}
                      nocarrierMessage={finishEnrollmentData?.lblNoCarrieMSG}
                    />
                  </div>
                </div>
              );
            })}
            <AlertComponent
              marginleft={false}
              propmessageType={finishEnrollmentData?.eoiForms?.messageBottomType === "DANGER" ? "danger clearfix" : " clearfix pb-3 pb-md-4"}
              propmessage={finishEnrollmentData?.eoiForms?.messageBottom}
            />
          </div>
        )}
        {lblBottomMessage && <AlertComponent propmessageType="primary" propmessage={lblBottomMessage} />}
        <div className="d-flex justify-content-between pt-5">
          <ButtonComponent btndata={buttonInput} />
        </div>
      </div>
    </>
  );
};
export default EnrollmentConfirmation;
