import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { cancelEnrollmentClick, getMainMenuContent, getWelcomeContent, navigatesToLifeChangeEvent, putWelcomeContent, setHeaderNavURL, setPageSetting } from "../../actions";
import { AlertComponent, ButtonComponent, ModalComponent } from "../../components";
import { isMobileDevice, navigateInitialState } from "../../constants";
import { RootState } from "../../reducers";
import { EventUtils, ObjectUtils, StringUtils, USER_TOKEN } from "../../utils";

// import infoIcon from "../../assets/icons/Info-fill.svg";
// import { USER_TOKEN } from "../../utils/Storage";

export const WelcomeScreen = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { client } = USER_TOKEN?.get();
  const { contentFooter, contentWelcome, putWelcomeResponse } = useSelector((state: RootState) => state.common);
  const { pageSetting } = useSelector((state: RootState) => state.event);
  const [isTruncated, setIsTruncated] = useState<boolean>(true);
  const [isModifyEnrollment, setIsModifyEnrollment] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const footerContent = contentFooter?.contentResource?.contents;
  const contents = contentWelcome?.contentResource?.contents;
  const configs: any = contentWelcome?.result;
  const lblHomeBanner = ObjectUtils.extractLabelFromObject(contents, "lblHomeBanner");
  const lblPageTitle = ObjectUtils.extractLabelFromObject(contents, "lblPageTitle");
  /**
   * This function will execute once this component
   * has been mounted.
   */
  //
  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
  };

  const onButtonClick = async (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    const conf = configs?.[name];
    if (conf) {
      if (name === "showLifeEvent") {
        navigatesToLifeChangeEvent(navigate);
        setHeaderNavURL(false);
      } else if (name === "showReviewEnrollment") {
        localStorage.removeItem("parameters");
        event.currentTarget.blur();
      } else {
        await putWelcomeContent(navigate, conf.btnLabel, null, configs[name]?.btnLabel, null);
      }
    }
  };

  const onCancelEnrollment = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    const conf = configs[name];

    const isModified = name === "showModifyEnrollment" ? true : false;
    const navProp = location.pathname === `/${client}/SSO` ? null : navigate;
    if (conf) {
      putWelcomeContent(
        navProp,
        conf.btnLabel,
        () => {
          setShowModal(true);
          setIsModifyEnrollment(isModified);
        },
        () => {
          navigate("/");
        },
        null
      );
    }
    event.currentTarget.blur();
  };

  useEffect(() => {
    const loadWelcome = async () => {
      if (!contentWelcome) {
        dispatch(await getWelcomeContent());
      }
      if (location?.state?.reload) {
        navigate("/", navigateInitialState);
        dispatch(await getWelcomeContent());
        dispatch(await getMainMenuContent(false));
      }
    };

    loadWelcome();

    if (lblPageTitle?.display !== null) {
      document.title = lblPageTitle.display;
    }
  }, [contentWelcome, dispatch, lblPageTitle.display, location?.state?.reload, navigate]);

  useEffect(() => {
    if (location?.pathname === `/${client}/SSO`) {
      window.history.replaceState(null, "", "/");
    }
    if (pageSetting === "undefined" || pageSetting?.name !== "WELCOME") {
      setPageSetting("WELCOME");
    }
  }, [client, location?.pathname]);

  const homeBanner = ObjectUtils.extractLabelFromObject(contents, "homeBanner");

  /* labelOrdering */
  const lblEnrollmentWaiting = ObjectUtils.extractLabelFromObject(contents, "lblEnrollmentWaiting");
  const lblEnrollmentClosed = ObjectUtils.extractLabelFromObject(contents, "lblEnrollmentClosed");
  const lblEnrollmentComplete = ObjectUtils.extractLabelFromObject(contents, "lblEnrollmentComplete");
  const lblEnrollmentWindow = ObjectUtils.extractLabelFromObject(contents, "lblEnrollmentWindow");
  const lblEnrollmentInstructions = ObjectUtils.extractLabelFromObject(contents, "lblEnrollmentInstructions");

  const truncatedHomePageText: any = configs?.homePageText?.substring(0, 200) || "";
  const lnkMore = ObjectUtils.extractLabelFromObject(contents, "lnkMore");
  const lnkLess = ObjectUtils.extractLabelFromObject(contents, "lnkLess");

  const lblPartialEnrollmentAlert = ObjectUtils.extractLabelFromObject(contents, "lblPartialEnrollmentAlert");
  const lblCompleteEnrollmentAlert = ObjectUtils.extractLabelFromObject(contents, "lblCompleteEnrollmentAlert");

  /* buttonOrdering */
  const lnkStartNewHireEnrollment = ObjectUtils.extractLabelFromObject(contents, "lnkStartNewHireEnrollment");
  const lnkStartAnnualEnrollment = ObjectUtils.extractLabelFromObject(contents, "lnkStartAnnualEnrollment");
  const lnkContinueEnrollment = ObjectUtils.extractLabelFromObject(contents, "lnkContinueEnrollment");
  const lnkModifyEnrollment = ObjectUtils.extractLabelFromObject(contents, "lnkModifyEnrollment");
  const lnkReviewEnrollment = ObjectUtils.extractLabelFromObject(contents, "lnkReviewEnrollment");
  const lnkCancelEnrollment = ObjectUtils.extractLabelFromObject(contents, "lnkCancelEnrollment");
  const lnkStartLifeEvent = ObjectUtils.extractLabelFromObject(contents, "lnkStartLifeEvent");

  const lblConfirmModifyDialogTitle = ObjectUtils.extractLabelFromObject(contents, "lblConfirmModifyDialogTitle");
  const yesButtonLabel = ObjectUtils.extractLabelFromObject(contents, "lblYes");
  const noButtonLabel = ObjectUtils.extractLabelFromObject(contents, "lblNo");

  const lblNewTab = ObjectUtils.extractLabelFromObject(footerContent, "lblNewTab");
  const reviewCoverageLnk = configs?.isReviewFuture ? "ReviewCoverage/CurrentCoverage?period=Future" : "ReviewCoverage/CurrentCoverage";

  const buttonInput = [
    configs?.showStartNewHireEnrollment && {
      variant: "secondary col-xs-12 col-md-6",
      display: lnkStartNewHireEnrollment?.display,
      name: "showStartNewHireEnrollment",
      onSubmit: onButtonClick
      //padlevel: "pb-3"
    },
    configs?.showStartAnnualEnrollment && {
      variant: "secondary col-xs-12 col-md-6",
      display: lnkStartAnnualEnrollment?.display,
      name: "showStartAnnualEnrollment",
      onSubmit: onButtonClick
      //padlevel: "pb-3"
    },
    configs?.showContinueEnrollment && {
      variant: "secondary col-xs-12 col-md-6",
      display: lnkContinueEnrollment?.display,
      name: "showContinueEnrollment",
      onSubmit: onButtonClick
      //padlevel: "pb-3"
    },
    configs?.showModifyEnrollment && {
      variant: "secondary col-xs-12 col-md-6",
      display: lnkModifyEnrollment?.display,
      name: "showModifyEnrollment",
      onSubmit: onCancelEnrollment
      // padlevel: "pb-3"
    },
    configs?.showReviewEnrollment && {
      // href: reviewCoverageLnk,
      // target: "__blank",
      variant: "secondary col-xs-12 col-md-6",
      display: lnkReviewEnrollment?.display,
      ariaLabel: lnkReviewEnrollment?.textToRead + " " + lblNewTab.textToRead,
      name: "showReviewEnrollment",
      onSubmit: (e: any) => {
        // if (e.key === "Enter") {
        const target = isMobileDevice ? "" : "_blank";
        const newWindow = window.open(reviewCoverageLnk!, target);
        if (newWindow) {
          newWindow.opener = null;
        }
        // window.open(reviewCoverageLnk!, "_blank");
        onButtonClick(e);
        // }
      }
      /* onMouseDown: (e: any) => {
        if (e.button === 0) {
          onButtonClick(e);
        }
      }, */
      //padlevel: "pb-3"
    },
    configs?.showCancelEnrollment && {
      variant: "secondary col-xs-12 col-md-6",
      display: lnkCancelEnrollment?.display,
      name: "showCancelEnrollment",
      onSubmit: onCancelEnrollment
      //padlevel: "pb-3"
    },
    configs?.showLifeEvent && {
      variant: "secondary col-xs-12 col-md-6",
      display: lnkStartLifeEvent?.display,
      name: "showLifeEvent",
      onSubmit: onButtonClick
      //padlevel: "pb-3"
    }
  ];

  const handleClose = (hideButtons = false) => {
    if (localStorage.getItem("hideButtons") === "true") {
      if (!hideButtons) localStorage.removeItem("hideButtons");
    }
    setShowModal(false);
    setIsModifyEnrollment(false);
  };

  const handleCancel = async () => {
    const selectedLink = "lnkStartAnnualEnrollment";
    if (isModifyEnrollment) {
      putWelcomeContent(
        navigate,
        selectedLink,
        () => {
          handleClose(true);
        },
        selectedLink,
        null
      );
    } else {
      await cancelEnrollmentClick(navigate, () => {
        getWelcomeContent();
        getMainMenuContent(false);
        handleClose();
      });
    }
  };

  const cancelEnrollment = {
    showModal: showModal,
    clickHandler: handleClose,
    head: {
      modalTitle: isModifyEnrollment ? lblConfirmModifyDialogTitle.display : lnkCancelEnrollment.display
    },
    body: {
      confirmBoxData: {
        messagetext: putWelcomeResponse?.alert,
        messagetextclass: "ml-popupmessagetext align-left"
      }
    },
    footer: {
      footerData: [
        {
          variant: "outline-secondary btn-cancel",
          display: noButtonLabel?.display,
          onSubmit: handleClose
        },
        {
          variant: "outline-secondary",
          display: yesButtonLabel?.display,
          onSubmit: handleCancel
        }
      ]
    }
  };

  return (
    <>
      {!StringUtils.isEmpty(lblHomeBanner?.display, true) && (
        <h1 className="caption" id="headingWelcome">
          {lblHomeBanner?.display}
        </h1>
      )}
      {!StringUtils.isEmpty(homeBanner?.textToRead, true) && <p id="mainWelcome" dangerouslySetInnerHTML={{ __html: homeBanner?.display }} />}
      {configs?.showEnrollmentWaiting && <p className="bold-text" dangerouslySetInnerHTML={{ __html: lblEnrollmentWaiting?.display }} />}
      {configs?.showEnrollmentClosed && <p className="bold-text" dangerouslySetInnerHTML={{ __html: lblEnrollmentClosed?.display }} />}
      {configs?.showEnrollmentComplete && <p className="bold-text" dangerouslySetInnerHTML={{ __html: lblEnrollmentComplete?.display }} />}
      {configs?.showEnrollmentWindow && <p className="bold-text" dangerouslySetInnerHTML={{ __html: lblEnrollmentWindow?.display }} />}
      {configs?.showEnrollmentInstructions && (
        <p
          dangerouslySetInnerHTML={{
            __html: lblEnrollmentInstructions?.display
          }}
        />
      )}
      {configs?.showHomePageText && (
        <div className="mb-1 mb-lg-1">
          <span
            className="pe-2"
            dangerouslySetInnerHTML={{
              __html: isTruncated ? truncatedHomePageText : configs?.homePageText
            }}
          />

          <Link
            to="#"
            className="btn-link"
            role="button"
            aria-label={isTruncated ? lnkMore?.textToRead : lnkLess?.textToRead}
            onKeyDown={(e) => EventUtils.keyPressed(e) && toggleTruncate()}
            onClick={toggleTruncate}
          >
            {isTruncated ? lnkMore?.display : lnkLess?.display}
          </Link>
        </div>
      )}
      {configs?.showPartialEnrollmentAlert && <AlertComponent marginleft={false} propmessageType="primary" propmessage={lblPartialEnrollmentAlert?.display} />}
      {configs?.showCompleteEnrollmentAlert && <AlertComponent marginleft={false} propmessageType="primary" propmessage={lblCompleteEnrollmentAlert?.display} />}
      <div className="welcome-btns row">
        <ButtonComponent shouldWrapDiv="row col-xs-12 col-md-12 col-lg-12  welcome-btn" btndata={buttonInput} />
      </div>
      <ModalComponent modalObject={cancelEnrollment}></ModalComponent>
    </>
  );
};

export default WelcomeScreen;
