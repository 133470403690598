import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getClientById, getSSOtoken, showError } from "../../actions";
import { DescriptionComponent, EnrollmentHeader, ModalComponent, NextPrevComponent, PagingComponent } from "../../components";
import { deviceType } from "../../constants";
import { RootState } from "../../reducers";
import { EventUtils, ObjectUtils, USER_TOKEN } from "../../utils";
import { MainMenu } from "../Menu";

export const BaseLayout = (props: any) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const needSSO = location?.search?.indexOf("u=") > -1 ? true : false;
  // const { needTwoFARegistration, needTwoFAValidation } = useSelector((state: RootState) => state.auth);
  const { isMenuLoaded } = useSelector((state: RootState) => state.common);
  const [isSignedInOrSSO, setSignedInOrSSO] = useState<boolean>(false);
  const [smokerUndefined, setsmokerUndefined] = useState<boolean>(false);
  const [combinedHeight, setCombinedHeight] = useState<number>(0);
  const [isDisclaimerModalVisible, setIsDisclaimerModalVisible] = useState<boolean>(false);
  const { contentWelcome, contentFooter, contentHeader: headerImages } = useSelector((state: RootState) => state.common);

  const { basePageTitle, stepDescription, pageSetting, lifeEventChangeContent } = useSelector((state: RootState) => state.event);

  const { flexDollars } = useSelector((state: RootState) => state.benefit);
  const { warningInfo } = flexDollars || {};

  const { confirmPopupRequired, confirmWarningMessage } = warningInfo || {};
  const profileData = useSelector((state: RootState) => state.profile.personalInfo);
  const { isSignedIn, languageSwitchRequired } = USER_TOKEN.get();

  // const isTFA = !authResponse && (USER_TOKEN.get()?.needTwoFARegistration || USER_TOKEN.get()?.needTwoFAValidation);
  const footerContent = contentFooter?.contentResource?.contents;

  const checkSSO = useCallback(() => {
    const clientName = location.pathname.split("/")[1];
    //let didSetClientName:any = false
    console.log("CLIENT NAME RECEIVED IN SSO-->", clientName);
    if (clientName) {
      dispatch(
        getClientById(
          clientName,
          () => {
            return true;
            // didSetClientName = true
          },
          (errorMessage) => {
            //didSetClientName = false;
            showError(errorMessage);
            navigate(`/403`);
            // return false;
          }
        )
      );
    }

    const query = location.search && location.search.indexOf("u=") > -1 ? location.search.replace("?", "").split("&") : [];
    /* const params = query?.map((item: any) => {
      const temp = item?.split("=");
      const temp_0 = temp[0] === "Lang" ? temp[0].toLowerCase() : temp[0];
      return [temp_0, temp[1]];
    }); */
    const params = query
      .map((item) => {
        const [key, value] = item.split("=");
        return [key === "Lang" ? key.toLowerCase() : key, value];
      })
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
    dispatch(getSSOtoken(params, navigate));
    navigate(`/${clientName}/SSO`);
    return true; //TODO
  }, [dispatch, location.pathname, location.search, navigate]);

  useEffect(() => {
    let _isSignedIn = isSignedIn;

    if (needSSO) {
      _isSignedIn = checkSSO();
    }

    if (props?.path === "home") {
      if (localStorage.getItem("isReview")) {
        localStorage.removeItem("isReview");
      }
      if (localStorage.getItem("hideButtons") === "true") {
        localStorage.removeItem("hideButtons");
      }
      if (localStorage.getItem("lifeEvent") === "true") {
        localStorage.removeItem("lifeEvent");
      }
    }
    setSignedInOrSSO(_isSignedIn);
    setsmokerUndefined(false);
  }, [checkSSO, isSignedIn, needSSO, props?.path]);

  const callProgress = async () => {
    setIsDisclaimerModalVisible(false);
    window.scrollTo(0, 0);
  };
  const toggleDisclaimer = () => {
    setIsDisclaimerModalVisible(!isDisclaimerModalVisible);
  };

  const isSmokerCallBack = () => {
    setsmokerUndefined(true);
  };

  useEffect(() => {
    const headerHeight = document?.querySelector("#mainHeader")?.clientHeight || 0;
    const footerHeight = document?.querySelector("#mainFooter")?.clientHeight || 0;
    const screenHeight = window.innerHeight;
    const combHt = screenHeight - (headerHeight + footerHeight + 0);
    if (combinedHeight === 0) {
      setCombinedHeight(combHt);
    }
  }, [combinedHeight]);

  useEffect(() => {
    if (props?.path === "home" && languageSwitchRequired) {
      EventUtils.changeLocale("fr", location);
    }
  }, [languageSwitchRequired, location, props?.path]);

  const logos = headerImages?.result;
  const { contentResource, result } = profileData || {};
  const { smokerStatus } = result || {};
  const contentImages = headerImages?.contentResource?.contents;
  const bannerAltText = ObjectUtils.extractLabelFromObject(contentImages, "welcomeBannerAltText");
  const btnPrevious = ObjectUtils.extractLabelFromObject(footerContent, "btnPrevious");

  const btnCancel = ObjectUtils.extractLabelFromObject(footerContent, "btnCancel");
  const btnNext = ObjectUtils.extractLabelFromObject(footerContent, "btnNext");

  const lnkStartLifeEvent = ObjectUtils.extractLabelFromObject(lifeEventChangeContent?.contentResource?.contents, "lnkStartLifeEvent") || "";
  const contents = contentWelcome?.contentResource?.contents || {};
  const lblLanguageSwitchMessageHeader = ObjectUtils.extractLabelFromObject(contents, "lblLanguageSwitchMessageHeader");
  const lblLanguageSwitchMessage = ObjectUtils.extractLabelFromObject(contents, "lblLanguageSwitchMessage");
  const shouldMountProgress = localStorage.getItem("isReview") || localStorage.getItem("lifeEvent") ? true : false;
  const noButtonLabel = ObjectUtils.extractLabelFromObject(contents, "btnCancel");
  const yesButtonLabel = ObjectUtils.extractLabelFromObject(contents, "lblYes");

  const pageTitle = location.pathname === "/LifeChangeEvent" ? lnkStartLifeEvent.display : basePageTitle || (pageSetting && pageSetting.display) || "";
  const custSaveSmokerErrorMessage = ObjectUtils.extractLabelFromObject(contentResource?.contents || [], "custSaveSmokerErrorMessage");

  /* console.log("pageTitle: ", pageTitle);
  console.log("basePageTitle: ", basePageTitle);
  console.log("pageSetting: ", pageSetting); */

  if (pageTitle !== null && pageTitle !== "") {
    document.title = pageTitle;
  }

  const flexGroupModal = {
    showModal: isDisclaimerModalVisible,
    clickHandler: toggleDisclaimer,
    head: {
      modalTitle: "&nbsp;"
    },
    body: {
      confirmBoxData: {
        messagetext: confirmWarningMessage
        // messagetextclass: "ml-popupmessagetext align-left"
      },
      showNextPrev: {
        cancelClicked: toggleDisclaimer,
        iAgree: yesButtonLabel,
        idontAgree: noButtonLabel,
        onClick: callProgress
      }
    }
  };

  return isSignedInOrSSO ? (
    <div className={`${props?.path === "home" ? "" : "container"}`} role="main">
      {props?.path === "home" && languageSwitchRequired && (
        <div className={`alert alert-primary mb-1`}>
          <span className={`bold-text`} aria-level={lblLanguageSwitchMessageHeader.textToRead}>
            {lblLanguageSwitchMessageHeader.display}
          </span>
          <div className="pt-1" aria-level={lblLanguageSwitchMessage.textToRead} dangerouslySetInnerHTML={{ __html: lblLanguageSwitchMessage.display }} />
        </div>
      )}
      {props?.path === "home" && logos?.welcomeBanner && (
        <div className="banner-holder">
          <div className="banner-align">
            <img
              className="welcome-banner"
              id="bannerID"
              onLoad={() => {
                console.log("OnLoad Called");
                setCombinedHeight(combinedHeight - (document?.querySelector("#bannerID")?.clientHeight || 0));
              }}
              src={logos?.welcomeBanner}
              alt={bannerAltText.textToRead}
            />
          </div>
        </div>
      )}
      <div className={`${props?.path === "home" ? "welcome-page-container container" : "page-container"}`}>
        <div
          className="main-content-holder row"
          //  style={{ height: props?.path === "home" ? "auto" : "auto" }}
        >
          {<div className="col-xs-10 col-lg-3 side-nav-holder">{<MainMenu />}</div>}
          <div className={`col-xs-12 col-lg-9 ${props?.path === "home" ? "content-holder" : "content-holder"}`} style={{ minHeight: combinedHeight + "px" }}>
            {/* <span id="headingMain" className="visuallyhidden">-</span> */}
            {/* {pageTitle ? <h1 className="caption personal-info-caption">{pageTitle}</h1> : ""} */}

            {deviceType === "Desktop" && (localStorage.getItem("isReview") || localStorage.getItem("lifeEvent")) && (
              <div className="topbutons">
                {!props.hideNavigation &&
                  (confirmPopupRequired && pageSetting?.link === "Flex?GroupID=1" ? (
                    <NextPrevComponent {...props} lblNext={btnNext} lblCancel={btnCancel} lblPrevious={btnPrevious} nextClick={toggleDisclaimer} onClick={callProgress} location={location} />
                  ) : smokerStatus === 2 && pageSetting?.link === "Profile" && result?.smokerDeclarationOptional === false ? (
                    <NextPrevComponent
                      {...props}
                      lblNext={btnNext}
                      lblCancel={btnCancel}
                      lblPrevious={btnPrevious}
                      onClick={callProgress}
                      location={location}
                      isSmoker={smokerStatus === 2 ? true : false}
                      isSmokerCallBack={isSmokerCallBack}
                    />
                  ) : (
                    <NextPrevComponent {...props} lblNext={btnNext} lblCancel={btnCancel} lblPrevious={btnPrevious} onClick={callProgress} location={location} />
                  ))}
              </div>
            )}
            {!props.hidePaging && shouldMountProgress && <PagingComponent />}
            <span id="headingMain" className="visuallyhidden">
              -
            </span>
            {pageTitle && <DescriptionComponent level={1} headingclassname={"caption personal-info-caption"} heading={pageTitle} />}

            {stepDescription && <div className="stepdescription mb-4" dangerouslySetInnerHTML={{ __html: stepDescription }} />}
            {!props.hideFlex && <EnrollmentHeader />}
            {isMenuLoaded && <props.benefit />}
            {/* {!props.hideNavigation && <NextPrevComponent {...props} lblNext={btnNext} lblCancel={btnCancel} lblPrevious={btnPrevious} onClick={callProgress} location={location} />} */}

            {smokerUndefined && result?.smokerStatus === 2 && result?.smokerDeclarationOptional === false && (
              <div className="alert alert-danger " role="alert" dangerouslySetInnerHTML={{ __html: custSaveSmokerErrorMessage.display }} />
            )}

            {!props.hideNavigation &&
              (confirmPopupRequired && pageSetting?.link === "Flex?GroupID=1" ? (
                <NextPrevComponent {...props} lblNext={btnNext} lblCancel={btnCancel} lblPrevious={btnPrevious} nextClick={toggleDisclaimer} onClick={callProgress} location={location} />
              ) : smokerStatus === 2 && pageSetting?.link === "Profile" && result?.smokerDeclarationOptional === false ? (
                <NextPrevComponent
                  {...props}
                  lblNext={btnNext}
                  lblCancel={btnCancel}
                  lblPrevious={btnPrevious}
                  onClick={callProgress}
                  location={location}
                  isSmoker={smokerStatus === 2 ? true : false}
                  isSmokerCallBack={isSmokerCallBack}
                />
              ) : (
                <NextPrevComponent {...props} lblNext={btnNext} lblCancel={btnCancel} lblPrevious={btnPrevious} onClick={callProgress} location={location} />
              ))}
          </div>
          {confirmPopupRequired && pageSetting?.link === "Flex?GroupID=1" && <ModalComponent modalObject={flexGroupModal} />}
        </div>
      </div>
    </div>
  ) : null;
};

export default BaseLayout;
