import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getContentFooterPage, setPageSetting } from "../../actions";
import { ButtonComponent, DescriptionComponent } from "../../components";
import { isMobileDevice } from "../../constants";
import { RootState } from "../../reducers";
import { ObjectUtils } from "../../utils";
import _ from "underscore";

export const ExternalLinks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [content, setContent] = useState<any>("");
  const screens = useSelector((state: RootState) => state.common.screens);
  const { contentFooter, contentFooterPage } = useSelector((state: RootState) => state.common);
  const { pageSetting } = useSelector((state: RootState) => state.event);
  const contentFooterPages = JSON.parse(sessionStorage.getItem("contentFooterPages") || "");
 
  useEffect(() => {
    contentFooterPage && setContent(contentFooterPage?.result?.content);
  }, [contentFooterPage]);
  
  useEffect(() => {
      dispatch(
        getContentFooterPage(contentFooterPages.section, (respone: any) => {
          setContent(respone?.result?.content);
        })
      );
  }, [contentFooterPages.section, dispatch]);

  const handleBack = () => {
    if (localStorage.getItem("policyName")) {
      const menuItem = _.find(screens, item => item.name === localStorage.getItem('policyName'))
      setPageSetting(localStorage.getItem("policyName"));
      localStorage.removeItem("policyName");
      navigate(`/${menuItem ? menuItem?.link : ""}`);
    } else navigate(`/${pageSetting?.link || ""}`);
  };

  const contents = contentFooter?.contentResource?.contents;
  const backButtonText = ObjectUtils.extractLabelFromObject(contents, "btnBack");
  console.log("backButtonText", backButtonText);

  const btnBack = backButtonText.display && [
    {
      display: backButtonText.display,
      variant: "link",
      rightpos: true,
      displayclass: "m-0 mx-3",
      className: "float-left",
      icon: faChevronLeft,
      onSubmit: handleBack
    }
  ];

  return (
    <>
      <div className="clearfix position-relative bg-white">
        {isMobileDevice ? (
          <div className={`backicon titlewithicon`}>
            <ButtonComponent btndata={btnBack} />
          </div>
        ) : (
          ""
        )}
        <DescriptionComponent headingclassname="text-center" level={1} heading={contentFooterPages?.display} />
        <div className="clearfix" dangerouslySetInnerHTML={{ __html: content }}></div>
      </div>
    </>
  );
};

export default ExternalLinks;
