/* eslint-disable @typescript-eslint/no-unused-vars */
import { Key, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getFooterContent } from "../../actions";
import { ObjectUtils } from "../../utils";
import { RootState } from "../../reducers";
import { USER_TOKEN } from "../../utils/Storage";

export const Footer = () => {
  const dispatch = useDispatch();
  const { contentFooter } = useSelector((state: RootState) => state.common);
  const { isSignedIn, client } = USER_TOKEN.get();
  /**
   * This function will execute once this component
   * has been mounted.
   */
  const loadFC = useCallback(() => {
    dispatch(getFooterContent());
  }, [dispatch]);

  useEffect(() => {
    !contentFooter && client && loadFC();
  }, [client, contentFooter, loadFC]);

  const handleClick = (value: any) => {
    const contentFooterPages: any = {};
    contentFooterPages.section = value.section;
    contentFooterPages.display = value.display;
    sessionStorage.setItem("contentFooterPages", JSON.stringify(contentFooterPages));
  };

  const contents = contentFooter?.contentResource?.contents;
  const footerData: any = contentFooter?.result;
  const lblNewTab = ObjectUtils.extractLabelFromObject(contents, "lblNewTab");
  const allLinks: any[] = [];
  footerData?.links?.map((value: any, index: Key) => {
    allLinks.push(
      <li key={index} className="top-footer-link">
        {value.isExternalLink === true ? (
          <a target="_blank" href={value.actinLink} rel="noopener noreferrer">
            {value.display}
          </a>
        ) : (
          <Link
            to={{
              pathname: `/Content/FooterContent`
            }}
            target="blank"
            onClick={() => handleClick(value)}
          >
            {value.display}
          </Link>
        )}
      </li>
    );
  });

  return (
    <div role="contentinfo" className={isSignedIn ? "footer fixed-bottom" : "footer fixed-bottom showfooter"} id="mainFooter">
      <div className="container">
        <div className="row">
          <div className={`col-12 flex-column d-sm-flex justify-content-sm-between flex-sm-row-reverse p-0`}>
            <ul>{allLinks}</ul>
            <span aria-hidden={false} dangerouslySetInnerHTML={{ __html: footerData?.copyright }}></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
